var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tinymceEditorBoxPage"},[_c('editor',{attrs:{"api-key":"7v7rdf4ceh3lhjt15sp2qd3hj6l429x52rt1bpbazd83zy1i","init":{
      height: 600,
      language: 'zh_CN',
      menubar: 'file edit insert view format table',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar:
        'undo redo | image | formatselect | bold italic backcolor | \
                                           alignleft aligncenter alignright alignjustify | \
                                           bullist numlist outdent indent | removeformat | help',
      // 图片读取前缀路径
      images_upload_base_path: this.$fileUrl,
      // 图片本地上传方法  点击上传后执行的事件
      images_upload_handler: function (blobInfo, success, failure) {
        // eslint-disable-next-line vue/this-in-template
        this$1.handleImgUpload(blobInfo, success, failure)
      }
    }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }